<template>
  <div class="creation-step">
    <div v-if="!isEditing" class="step-heading">
      <h1 class="step-heading__title">Describe your work history</h1>
      <p class="step-heading__text">
        Show employers where you worked before. You may add voluntary jobs and
        internships too.
      </p>
    </div>

    <draggable
      v-model="workHistories"
      tag="div"
      class="experience"
      v-bind="dragOptions"
      :sort="false"
      :disabled="!!editingExperience"
      handle=".preview-controls__btn--move"
      @start="dragStart"
      @end="dragEnd"
    >
      <transition-group
        type="transition"
        tag="div"
        :name="isDrag ? 'flip-list' : null"
      >
        <div
          v-for="work of workHistories"
          :key="work.id"
          class="experience__item"
        >
          <WorkHistoryForm
            :isEditing="isEditing"
            :experience="work"
            :disabled="isEditingExperience"
            :activeId="editingExperience"
            :isMobileDisplay="isMobileDisplay"
            @edit="openEditing"
            @cancel="cancelWorkEditing"
            @create="createWorkHistory"
            @update="updateWorkHistory"
            @remove="removeWorkHistory"
            @openPrefilled="openPrefilled"
            @closePrefilled="closePrefilled"
          />
        </div>
      </transition-group>
    </draggable>

    <AddingBlock
      v-if="isShowAddButton(isEditingExperience)"
      @addBlock="addNewData(isEditingExperience)"
    >
      Add new position
    </AddingBlock>

    <div v-if="!isEditing" class="creation-form-header">
      <button
        class="creation-form-header__preview"
        type="button"
        @click.prevent="openSidebar"
      >
        <AppIcon componentName="SearchIcon" />
      </button>
    </div>

    <FooterButton
      v-if="!isShowBackButton"
      :isEditing="isEditing"
      :ishideMobileFooter="hideMobileFooter"
      @goNextStep="goNextStep"
    ></FooterButton>

    <FooterButtonv2
      v-if="isShowBackButton"
      :isEditing="isEditing"
      :ishideMobileFooter="hideMobileFooter"
      @goNextStep="goNextStep"
    ></FooterButtonv2>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "vue2-datepicker/index.css";

import { CREATING_RESUME_NAMES } from "@/helpers/rout-constants";
import HelperResumeHash from "@/helpers/resume-hash";
import HelperEditingHash from "@/helpers/editing-hash";
import AppToast from "@/helpers/toast-methods";

import { resumeCreationMixin } from "@/mixins/ResumeCreationStepsMixin";
import { experienceMixin } from "@/mixins/ExperienceMixin";
import WorkHistoryForm from "@/views/resume-creation/step-2/components/WorkHistoryForm";
import AddingBlock from "@/components/creation/AddingBlock";
import { setIntermediateResume } from "@/helpers/save-intermediate-data";
import {
  COMPONENT_NAMES,
  LOCAL_COMPONENTS_NAMES,
} from "@/helpers/components-names";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import { eventBus } from "@/helpers/event-bus";
import CookieHelper from "@/helpers/cookie-helper";
import FooterButton from "@/components/creation/FooterButton";
import FooterButtonv2 from "@/components/creation/FooterButtonv2";

export default {
  name: COMPONENT_NAMES.RESUME_CREATION.WORK_HISTORY,
  components: {
    AddingBlock,
    WorkHistoryForm,
    draggable,
    FooterButton,
    FooterButtonv2,
  },
  mixins: [resumeCreationMixin, experienceMixin, mixpanelMixin],

  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      initialWorkHistories: "resumeCreation/getWorkHistory",
      defaultWork: "resumeCreation/getDefaultWorkHistory",
    }),
  },

  data() {
    return {
      workHistories: [],
      nextStepName: CREATING_RESUME_NAMES.STEP_3,
      prevStepName: CREATING_RESUME_NAMES.STEP_1,
      hideMobileFooter: false,
      isShowBackButton: false,
    };
  },

  methods: {
    isShowAddButton(isEditingExperience) {
      let hide_add = CookieHelper.getFlag("hide_add", "");
      if (hide_add == "1") {
        if (isEditingExperience) {
          return false;
        }
      }
      return true;
    },

    resumeHash() {
      return this.isEditing ? HelperEditingHash.get() : HelperResumeHash.get();
    },

    openPrefilled() {
      this.hideMobileFooter = true;
      this.$forceUpdate();

      this.mixpanelTrack("V2-SUGGESTEDDESC-WORKHISTORY");
    },

    closePrefilled() {
      this.hideMobileFooter = false;
      this.$forceUpdate();
    },

    async addNewData(isEditingExperience) {
      if (isEditingExperience) {
        if (this.checkValidation && this.checkValidation()) {
          if (
            document.querySelector(".experience-form.experience-form--active")
          ) {
            const activeForm = document.querySelector(
              ".experience-form.experience-form--active"
            );
            activeForm
              .querySelector(".experience-form-btns__item--submit")
              .click();
          }

          await this.$store.dispatch("loader/pending");

          setTimeout(() => {
            this.addExperience("workHistories", this.defaultWork);
          }, 1000);
        }
      } else {
        this.addExperience("workHistories", this.defaultWork);
      }
      await this.$store.dispatch("loader/done");
    },

    async createWorkHistory(workHistory) {
      this.editingExperience = null;

      const temporaryId = workHistory.id;
      delete workHistory.id;
      workHistory.resume_hash = this.resumeHash();

      await this.$store.dispatch("loader/pending");

      try {
        const newExperience = await this.$store.dispatch(
          "resumeCreation/createWorkHistory",
          workHistory
        );

        await setIntermediateResume(this.resumeHash());

        const temporaryExperienceIndex = this.workHistories.findIndex(
          (localExperience) => {
            return localExperience.id === temporaryId;
          }
        );

        this.workHistories[temporaryExperienceIndex].id = newExperience.id;

        delete this.workHistories[temporaryExperienceIndex].temporary;
      } catch (e) {
        console.error(e);

        this.editingExperience = temporaryId;

        // this.cancelWorkEditing();
      }

      this.mixpanelTrack("V2-ADDJOB", {
        JobTitle: workHistory.job_title,
      });

      this.mixpanelTrack("V2-ADDEMPLOYER", {
        Employer: workHistory.employer,
      });

      await this.$store.dispatch("loader/done");
    },

    async updateWorkHistory(workHistory, resetWorks = true) {
      workHistory.resume_hash = this.resumeHash();

      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch(
          "resumeCreation/updateWorkHistory",
          workHistory
        );

        if (resetWorks) {
          await this.$store.dispatch(
            "resumeCreation/getResume",
            this.resumeHash()
          );
          this.cancelWorkEditing();
          // AppToast.toastSuccess("Work history was successfully updated!");
        }
      } catch (e) {
        console.error(e);
      }

      await this.$store.dispatch("loader/done");
    },

    async removeWorkHistory(workHistoryId) {
      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch("resumeCreation/removeWorkHistory", {
          id: workHistoryId,
          hash: this.resumeHash(),
        });
        await this.cancelWorkEditing();
        await this.resetIndexes(
          this.workHistories,
          (data) =>
            this.$store.dispatch("resumeCreation/updateWorkHistoryGroup", data),
          this.cancelWorkEditing
        );
      } catch (e) {
        console.error(e);
      }

      await this.$store.dispatch("loader/done");
    },

    cancelWorkEditing() {
      this.editingExperience = null;

      this.setInitialExperience(this.initialWorkHistories, "workHistories");

      setIntermediateResume(this.resumeHash());
    },

    async dragEnd() {
      this.isDrag = false;

      await this.resetIndexes(
        this.workHistories,
        (data) =>
          this.$store.dispatch("resumeCreation/updateWorkHistoryGroup", data),
        this.cancelWorkEditing
      );
    },

    /**
     *
     * I had to do this because this functionality was invented at the end of development.
     *
     * Be careful.
     * If you change the nesting of components, then this function will stop working correctly.
     * Here I go into each nested component and trigger the validation function, and then push the result up to the top.
     * There are similar functions at every step of creation / editing.
     *
     * @returns {boolean}
     */
    checkValidation() {
      if (!this.workHistories.length) return true;

      const draggable = this.$children.find((componentForm) => {
        return componentForm.$options.name === "draggable";
      });

      const workHistoryForms = draggable.$children[0].$children.filter(
        (componentForm) => {
          return (
            componentForm.$options.name ===
            LOCAL_COMPONENTS_NAMES.WORK_HISTORY_FORM
          );
        }
      );

      let isValidForms = true;
      workHistoryForms.forEach((workHistoryForm) => {
        if (!workHistoryForm.checkValidationForm()) {
          isValidForms = false;
        }
      });

      return isValidForms;
    },
  },

  async mounted() {
    let back_btn = CookieHelper.getFlag("back_btn", "");
    if (back_btn == "1") {
      this.isShowBackButton = true;
    }
  },

  async created() {
    let vm = this;
    this.mixpanelTrack("V2-WORKHISTORY");
    await this.$store.dispatch("loader/pending");
    await this.getInitialResume(this.resumeHash());
    this.setInitialExperience(this.initialWorkHistories, "workHistories");
    await this.$store.dispatch("loader/done");
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/modules/work-education-skill-steps.scss";
@import "../../../assets/scss/modules/form.scss";
.creation-form-header__preview.order-md-2 {
  position: absolute;
  top: 0 !important;
  right: 9px;
  background: #fff;
  width: 70px;
  margin: 12px;
  padding: 2px;
  display: block;
  min-width: auto;
  z-index: 22;
  border: 2px solid #4874e6;
  border-radius: 30px;
}

button.creation-form-header__preview.order-md-2 svg {
  width: 17px;
  fill: #4874e6;
}
</style>
