<template>
  <button
    type="button"
    class="adding"
    :class="{ 'adding--disabled': disabled }"
    @click.prevent="addBlock"
  >
    <AppIcon componentName="PlusIcon" class="adding__icon" />
    <p class="adding__text">
      <slot />
      <template v-if="!$slots.default">Add new...</template>
    </p>
  </button>
</template>

<script>
export default {
  name: "AddingBlock",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    addBlock() {
      if (this.disabled) return;

      this.$emit("addBlock");
    },
  },
};
</script>

<style lang="scss" scoped>
.adding {
  height: 95px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #8c8f96;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;

  &__icon {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }

  &__text {
    font-size: 18px;
    transition: all 0.3s ease;
    color: $body-color;
  }

  &:hover {
    border-color: $primary;
    background-color: lighten($primary, 35%);

    .adding__icon {
      fill: $primary;
    }

    .adding__text {
      color: $primary;
    }
  }

  &--disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
  }
}
</style>
