<template>
  <form
    class="experience-form"
    :class="{
      'experience-form--active': isActive,
    }"
    :key="refreshForm"
    @submit.prevent="submitForm"
  >
    <div class="preview" :class="{ 'preview--invalid': $v.$error }">
      <div class="preview-heading">
        <h3 class="preview-heading__title">
          {{ experience.job_title }}
          <span>( {{ experience.employer }} )</span>
        </h3>

        <p class="preview-heading__dates">{{ formattedWorkTiming }}</p>
      </div>

      <div class="preview-controls-wrap" v-click-outside="closeMobileControls">
        <button
          class="mobile-control-btn"
          :class="{ 'mobile-control-btn--opened': isOpenMobileControls }"
          @click.stop.prevent="toggleMobileControls"
        >
          <AppIcon
            componentName="DotsVerticalIcon"
            class="control-btn-icon control-btn-icon--default"
          />
          <AppIcon
            componentName="CloseIcon"
            class="control-btn-icon control-btn-icon--close"
          />
        </button>

        <transition name="fade">
          <div
            v-if="isMobileDisplay ? isOpenMobileControls : true"
            class="preview-controls"
          >
            <div class="preview-controls__btn preview-controls__btn--move">
              <AppIcon componentName="MoveIcon" />
            </div>

            <span class="preview-controls__separator"></span>

            <button
              type="button"
              class="preview-controls__btn"
              @click.prevent="onClickEditForm"
            >
              <AppIcon componentName="EditIcon" />
            </button>

            <span class="preview-controls__separator"></span>

            <button
              class="preview-controls__btn"
              @click.prevent="removeExperience"
            >
              <AppIcon componentName="TrashIcon" />
            </button>
          </div>
        </transition>
      </div>
    </div>

    <transition name="fade">
      <span
        v-if="activeId !== null && !isActive"
        class="experience-form-mask"
      ></span>
    </transition>

    <div class="row creation-form-row">
      <div class="creation-form__col col-xl-6">
        <AppFieldSelect
          class="creation-form-field first-focus"
          label="Job title"
          required
          :value="experience.job_title"
          :getOptionHandler="getJobTitles"
          :isInvalid="$v.experienceData.job_title.$error"
          @input="setField($event, 'job_title')"
          :showResultBox="false"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        >
          <template v-slot:error>
            <p v-if="!$v.experienceData.job_title.required">
              This field is required
            </p>
          </template>
        </AppFieldSelect>
      </div>

      <div class="creation-form__col col-xl-6">
        <AppFieldSelect
          class="creation-form-field"
          label="Employer"
          required
          :value="experience.employer"
          :getOptionHandler="getCompany"
          :isInvalid="$v.experienceData.employer.$error"
          @input="setField($event, 'employer')"
          :showResultBox="false"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        >
          <template v-slot:error>
            <p v-if="!$v.experienceData.employer.required">
              This field is required
            </p>
          </template>
        </AppFieldSelect>
      </div>

      <div class="creation-form__col col-xl-6">
        <AppField
          class="creation-form-field"
          label="City"
          :value="experience.city"
          :placeHolder="geoCity"
          @input="setField($event, 'city')"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        >
        </AppField>
      </div>

      <div class="creation-form__col col-xl-6">
        <AppField
          class="creation-form-field"
          :label="labelState"
          :placeHolder="geoState"
          :value="experience.state"
          @input="setField($event, 'state')"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        >
        </AppField>
      </div>

      <div class="creation-form__col col-xl-3">
        <date-picker
          ref="datepicker"
          v-model="experienceData.start_date"
          :disabled-date="futureDate"
          type="month"
          format="MMMM YYYY"
          value-type="format"
        >
          <template v-slot:input>
            <AppDateField
              class="creation-form-field"
              label="Start date"
              type="monthandyear"
              isBlockedWriting
              :value="experienceData.start_date"
              :isInvalid="$v.experienceData.start_date.$error"
              @change="setField($event, 'start_date')"
            >
              <template v-slot:error>
                <p v-if="!$v.experienceData.start_date.incorrectDate">
                  Chosen date can`t be later than yesterday.
                </p>
              </template>
            </AppDateField>
          </template>
        </date-picker>
      </div>

      <div class="creation-form__col col-xl-3">
        <date-picker
          ref="datepicker"
          :key="refreshEndDate"
          v-model="experienceData.end_date"
          :disabled-date="futureDate"
          type="month"
          format="MMMM YYYY"
          value-type="format"
          @change="setEndDate"
        >
          <template v-slot:input>
            <AppDateField
              class="creation-form-field"
              label="End date"
              type="monthandyear"
              isBlockedWriting
              :value="experienceData.end_date || ''"
              :isInvalid="$v.experienceData.end_date.$error"
              @change="setField($event, 'end_date')"
            >
              <template v-slot:error>
                <p v-if="!$v.experienceData.end_date.requiredStartDate">
                  "Start date" is required
                </p>
                <p v-if="!$v.experienceData.end_date.incorrectDate">
                  Chosen date must be later than "Start date"
                </p>
              </template>
            </AppDateField>
          </template>
        </date-picker>
      </div>

      <div class="creation-form__col col-xl-12">
        <AppCheckbox
          :value="isWorkHereNow"
          @change="setCurrentlyDateWork"
          label="I currently work here"
        />
      </div>

      <div class="creation-form__col col-xl-12">
        <input type="hidden" :value="experience.responsibilities" />
        <AppTextEditor
          :value="experience.responsibilities"
          label="Responsibilities and achievements"
          enablePrefilled
          :isInvalid="$v.experienceData.responsibilities.$error"
          @searchPrefilled="getPrefilled"
          @input="setField($event, 'responsibilities')"
          :preFillDefaultValue="experience.job_title"
          preFillPlaceHolder="Search by Job Title"
          @openPrefilled="openPrefilled"
          @closePrefilled="closePrefilled"
          v-if="isActive"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          :isEditing="isEditing"
        >
          <template v-slot:error>
            <p v-if="!$v.experienceData.responsibilities.minLength">
              Min number of characters
              {{ $v.experienceData.responsibilities.$params.minLength.min }}
            </p>

            <p v-if="!$v.experienceData.responsibilities.maxLength">
              Max number of characters
              {{ $v.experienceData.responsibilities.$params.maxLength.max }}
            </p>
          </template>
        </AppTextEditor>
      </div>
    </div>

    <div v-show="isEditing" class="experience-form-btns">
      <AppButton
        type="submit"
        title="Save"
        class="experience-form-btns__item experience-form-btns__item--submit"
        className="primary"
      />

      <button
        type="button"
        class="experience-form-btns__item experience-form-btns__item--cancel"
        @click.prevent="cancel"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import $ from "jquery";

import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

import { LOCAL_COMPONENTS_NAMES } from "@/helpers/components-names";
import { experienceFormMixin } from "@/mixins/ExperienceFormMixin";
import AppTextEditor from "@/components/ui/AppTextEditor";
import AppCheckbox from "@/components/ui/AppCheckbox";
import AppDateField from "@/components/ui/AppDateField";
import AppFieldSelect from "@/components/ui/AppFieldSelect";
import CookieHelper from "@/helpers/cookie-helper";

$(function () {
  $(".mx-datepicker").bind("dblclick", function () {
    $(".mx-datepicker").val();
  });
});

export default {
  name: LOCAL_COMPONENTS_NAMES.WORK_HISTORY_FORM,
  mixins: [experienceFormMixin],
  components: {
    AppTextEditor,
    AppCheckbox,
    DatePicker,
    AppDateField,
    AppFieldSelect,
  },

  mounted() {
    // if ($(".first-focus input:text").last().val() == "") {
    //   $(".first-focus input:text").last().focus();
    // }
  },

  provide() {
    const prefilled = {};

    Object.defineProperty(prefilled, "prefilledData", {
      enumerable: true,
      get: () => this.prefilledData,
    });

    return {
      prefilled,
    };
  },

  props: {
    experience: {
      type: Object,
      required: true,
    },

    activeId: {
      type: [Number, String],
    },

    isMobileDisplay: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  validations() {
    return {
      experienceData: {
        job_title: {
          required,
        },
        employer: {
          required,
        },
        start_date: {
          incorrectDate(chosenDate) {
            if (chosenDate == "" || chosenDate == null) {
              return true;
            }

            if (chosenDate) {
              chosenDate = this.formatDate(chosenDate);
            }

            const chosenUserDate = new Date(chosenDate);
            let dateAllowed = new Date();
            dateAllowed.setDate(dateAllowed.getDate() - 1);

            return chosenUserDate.getTime() < dateAllowed.getTime();
          },
        },
        end_date: {
          requiredStartDate(chosenDate, fields) {
            if (chosenDate != "" && fields.start_date == null) {
              return false;
            } else {
              return true;
            }
          },
          incorrectDate(chosenDate, fields) {
            if (fields.start_date) {
              fields.start_date = this.formatDate(fields.start_date);
            }

            const startDate = new Date(fields.start_date);

            if (!startDate || this.isWorkHereNow) {
              return true;
            }

            if (chosenDate) {
              chosenDate = this.formatDate(chosenDate);
            }
            const chosenUserDate = new Date(chosenDate);

            return chosenUserDate.getTime() > startDate.getTime();
          },

          required() {
            if (!this.isWorkHereNow) {
              return !!this.experienceData.end_date;
            }

            return true;
          },
        },
        responsibilities: {
          // minLength: minLength(100),
          // maxLength: maxLength(1000),
        },
      },
    };
  },

  computed: {
    formattedWorkTiming() {
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (
        this.experienceData.start_date == "" ||
        this.experienceData.start_date == null
      ) {
        return "";
      }

      const formattedTime = [];

      const startTime = new Date(this.experienceData.start_date);
      const formattedStartTime =
        month[startTime.getMonth()] + " " + startTime.getFullYear();
      formattedTime.push(formattedStartTime);

      if (this.experienceData.end_date) {
        let formattedEndTime = "";
        if (this.experienceData.end_date.indexOf(" ") !== -1) {
          formattedEndTime = ` - ${this.experienceData.end_date} `;
        } else {
          const endTime = new Date(this.experienceData.end_date);
          formattedEndTime = ` - ${
            month[endTime.getMonth()]
          } ${endTime.getFullYear()}`;
        }

        formattedTime.push(formattedEndTime);
      }

      return formattedTime.join("");
    },
  },

  data() {
    return {
      start_date: "09.10.2019",
      isWorkHereNow: false,
      refreshEndDate: this.$uuid.v4(),
      geoCity: "",
      geoState: "",
      labelState: "State",
    };
  },

  methods: {
    ...mapActions({
      getJobTitles: "resumeCreation/getJobTitles",
    }),
    async getCompany(input) {
      // remove apostrophe
      input = input.replace(/[\u0027\u02BC\u2018\u2019]/g, "");
      return await this.$store.dispatch("resumeCreation/getCompanies", input);
    },

    setEndDate() {
      this.isWorkHereNow = false;
    },

    async getPrefilled(querySearch) {
      try {
        const prefilled = await this.$store.dispatch(
          "resumeCreation/getResumeWorkPrefilled",
          querySearch
        );

        this.$set(this, "prefilledData", prefilled);
      } catch (e) {
        console.error(e);
      }
    },

    setCurrentlyDateWork(state) {
      this.isWorkHereNow = state;

      if (state) {
        this.experienceData.end_date = "";
        this.refreshEndDate = this.$uuid.v4();
      }
    },
    onClickEditForm() {
      localStorage.setItem(
        "work_history_form_data",
        JSON.stringify(this.experienceData)
      );
      this.editForm();
    },
    cancel() {
      let data = localStorage.getItem("work_history_form_data");
      if (data) {
        data = JSON.parse(data);
        this.setField(data.job_title, "job_title");
        this.setField(data.employer, "employer");
        this.setField(data.city, "city");
        this.setField(data.start_date, "start_date");
        this.setField(data.end_date, "end_date");
        this.setField(data.responsibilities, "responsibilities");
        this.setCurrentlyDateWork(!data.end_date);
        localStorage.removeItem("work_history_form_data");
      }
      this.cancelEditing();
    },

    futureDate(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    formatDate(date) {
      if (date.indexOf(" ") !== -1 && date.indexOf(",") === -1) {
        // if the date is in the format "MMMM yyyy"
        return date.split(" ")[0] + " " + 1 + ", " + date.split(" ")[1];
      }
      return date;
    },

    openPrefilled() {
      this.$emit("openPrefilled");
    },

    closePrefilled() {
      this.$emit("closePrefilled");
    },
  },

  created() {
    this.setCurrentlyDateWork(!this.experience.end_date);
    this.geoCity = CookieHelper.getFlag("city", "");

    let countryCode = CookieHelper.getFlag("countrycode", "");
    let state = CookieHelper.getFlag("state", "");
    let country = CookieHelper.getFlag("country", "");
    if (countryCode != "US") {
      this.labelState = "Country";
      this.geoState = country;
    } else {
      this.geoState = state;
    }
  },
};
</script>

<style lang="scss" scoped>
.mx-datepicker {
  width: 100%;
}
@media (min-width: 992px) {
  .preview-heading__title,
  .preview-heading__title span {
    font-size: 21px !important;
  }
}
</style>
