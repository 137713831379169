<template>
  <div v-if="resume" class="editing-wrap">
    <div class="editing-wrap-heading">
      <h1 class="editing-wrap-heading__title">Edit Resume</h1>
    </div>

    <EditingPart
      title="Header"
      :openedEditingPart="openedEditingPart"
      :namesValidationComponents="NAME_VALIDATION_COMPONENTS"
      @toggle="toggleEditingPart"
      @close="closeEditingPart"
    >
      <template v-slot:subText>
        Show employers where you worked before. You may add voluntary jobs and
        internships too.
      </template>

      <FirstStepResumeEditing isEditing />
    </EditingPart>

    <EditingPart
      title="Work History"
      :openedEditingPart="openedEditingPart"
      :namesValidationComponents="NAME_VALIDATION_COMPONENTS"
      class="with-prefilled"
      @toggle="toggleEditingPart"
      @close="closeEditingPart"
    >
      <template v-slot:subText>
        Show employers where you worked before. You may add voluntary jobs and
        internships too.
      </template>

      <StepWorkHistoryResumeCreation isEditing />
    </EditingPart>

    <EditingPart
      title="Education"
      :namesValidationComponents="NAME_VALIDATION_COMPONENTS"
      :openedEditingPart="openedEditingPart"
      class="with-prefilled"
      @toggle="toggleEditingPart"
      @close="closeEditingPart"
    >
      <template v-slot:subText>
        List down your education-related information, including the highest
        degree and honors you earned.
      </template>

      <StepEducationResumeCreation isEditing />
    </EditingPart>

    <EditingPart
      title="Professional Skills"
      :namesValidationComponents="NAME_VALIDATION_COMPONENTS"
      :openedEditingPart="openedEditingPart"
      @toggle="toggleEditingPart"
      @close="closeEditingPart"
    >
      <template v-slot:subText>
        Describe your abilities and competencies that help you deliver
        outstanding results.
      </template>

      <StepSkillsResumeCreation isEditing />
    </EditingPart>

    <EditingPart
      title="Summary"
      :namesValidationComponents="NAME_VALIDATION_COMPONENTS"
      :openedEditingPart="openedEditingPart"
      class="with-prefilled"
      @toggle="toggleEditingPart"
      @close="closeEditingPart"
    >
      <template v-slot:subText>
        Tell employers why you're the best choice for the job.
      </template>

      <StepSummaryResumeCreation class="edit-summary" isEditing />
    </EditingPart>

    <div class="editing-footer d-lg-none">
      <button
        class="creation-form-footer__preview"
        type="button"
        @click.prevent="openSidebar"
      >
        <AppIcon componentName="SearchIcon" />
      </button>
      <AppButton
        title="Save and close"
        className="primary"
        class="creation-form-footer__submit"
        @click.prevent="saveEditing"
      />

      <button class="creation-form-footer__prev" @click.prevent="cancelEditing">
        Exit without saving
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import EditingHash from "@/helpers/editing-hash";
import AppToast from "@/helpers/toast-methods";
import { eventBus } from "@/helpers/event-bus";
import EditingPart from "@/views/template-editing/components/EditingPart";
import FirstStepResumeEditing from "@/views/template-editing/resume-editing/FirstStepResumeEditing";
import StepWorkHistoryResumeCreation from "@/views/resume-creation/step-2/StepWorkHistoryResumeCreation";
import StepEducationResumeCreation from "@/views/resume-creation/step-3/StepEducationResumeCreation";

import StepSkillsResumeCreation from "@/views/resume-creation/step-4/StepSkillsResumeCreation";
import StepSummaryResumeCreation from "@/views/resume-creation/step-5/StepSummaryResumeCreation";
import { setIntermediateResume } from "@/helpers/save-intermediate-data";
import { USER_ACCOUNT_NAMES } from "@/helpers/rout-constants";
import { COMPONENT_NAMES } from "@/helpers/components-names";

const NAME_VALIDATION_COMPONENTS = [
  COMPONENT_NAMES.RESUME_CREATION.BASE_INFO_EDITING,
  COMPONENT_NAMES.RESUME_CREATION.WORK_HISTORY,
  COMPONENT_NAMES.RESUME_CREATION.EDUCATION,
  COMPONENT_NAMES.RESUME_CREATION.SKILLS,
  COMPONENT_NAMES.RESUME_CREATION.SUMMARY,
];

export default {
  name: "ResumeEditing",
  components: {
    EditingPart,
    FirstStepResumeEditing,
    StepWorkHistoryResumeCreation,
    StepEducationResumeCreation,
    StepSkillsResumeCreation,
    StepSummaryResumeCreation,
  },

  computed: {
    ...mapGetters({
      resume: "resumeCreation/getResume",
    }),
  },

  data() {
    return {
      hashForClone: null,
      templateHash: null,
      openedEditingPart: null,
      NAME_VALIDATION_COMPONENTS,
    };
  },

  methods: {
    toggleEditingPart(editingPart) {
      if (editingPart.opened) {
        this.openedEditingPart = null;
        return;
      }

      this.openedEditingPart = editingPart.id;
    },

    closeEditingPart() {
      this.openedEditingPart = null;
    },

    async initialData() {
      if (!this.$route?.query?.hash) {
        AppToast.toastError("No template selected for editing...");
        await this.$router.push({ name: USER_ACCOUNT_NAMES.USER_DASHBOARD });
        return;
      }

      await this.$store.dispatch("loader/pending");

      this.hashForClone = this.$route?.query?.hash;

      try {
        if (this.$route?.params?.duplicate) {
          await this.$store.dispatch(
            "resumeCreation/cleanResume",
            this.hashForClone
          );

          const { hash } = await this.$store.dispatch(
            "resumeCreation/duplicateResume",
            {
              hash: this.hashForClone,
              isHidden: true,
            }
          );

          EditingHash.set(hash);
          this.templateHash = hash;
        } else {
          this.templateHash = EditingHash.get();
        }
        await this.$store.dispatch("loader/pending");

        await this.$store.dispatch(
          "resumeCreation/getResume",
          this.templateHash
        );

        await this.$store.dispatch("loader/pending");

        await setIntermediateResume(this.templateHash);
      } catch (e) {
        console.error(e);
        await this.$store.dispatch("loader/done");
        await this.$router.push({ name: USER_ACCOUNT_NAMES.USER_DASHBOARD });
      }
      await this.$store.dispatch("loader/done");
      await this.$store.dispatch("loader/done");
    },

    openSidebar() {
      eventBus.$emit("openCreationSidebar");
    },

    async saveEditing() {
      await this.$store.dispatch("loader/pending");

      if (!this.triggerStepsValidation()) {
        AppToast.toastError("Some fields are not filled... ");
        await this.$store.dispatch("loader/done");
        return;
      }

      try {
        await this.$store.dispatch("resumeCreation/forceUpdateResume", {
          hash: this.templateHash,
          is_draft: false,
          is_hidden: false,
        });

        await this.$store.dispatch(
          "userDashboard/removeResume",
          this.hashForClone
        );

        await this.$store.dispatch(
          "resumeCreation/cleanResume",
          this.templateHash
        );

        await this.$router.push({ name: USER_ACCOUNT_NAMES.USER_DASHBOARD });
      } catch (e) {
        console.error(e);
      }

      await this.$store.dispatch("loader/done");
    },

    async cancelEditing() {
      try {
        await this.$store.dispatch(
          "userDashboard/removeResume",
          this.templateHash
        );

        await this.$store.dispatch(
          "resumeCreation/cleanResume",
          this.templateHash
        );

        AppToast.toastSuccess("Your changes were not saved...");
        EditingHash.clear();
        await this.$router.push({ name: USER_ACCOUNT_NAMES.USER_DASHBOARD });
      } catch (e) {
        console.error(e);
      }
    },

    triggerStepsValidation() {
      let isValidSteps = true;

      const editPartComponents = this.$children.filter((component) => {
        return component.$options.name === COMPONENT_NAMES.EDITING_PART;
      });

      editPartComponents.forEach((component) => {
        const isValid = component.validateEditingSteps();
        if (!isValid) {
          isValidSteps = false;
        }
      });

      return isValidSteps;
    },
  },

  created() {
    this.initialData();
    eventBus.$on("saveEditing", this.saveEditing);
    eventBus.$on("cancelEditing", this.cancelEditing);
  },

  beforeDestroy() {
    eventBus.$off("saveEditing", this.saveEditing);
    eventBus.$off("cancelEditing", this.cancelEditing);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .editing {
  margin-bottom: 30px;

  .creation-step {
    min-height: initial;
  }
}

.editing-wrap {
  width: 100%;
  min-height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
}

.editing-wrap-heading {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

::v-deep .with-prefilled.editing--opened {
  @media (min-width: map_get($grid-breakpoints, lg)) and (max-width: map_get($grid-breakpoints, xl)) {
    overflow: visible;
  }
}

::v-deep .edit-summary {
  padding-bottom: 0;

  .ql-editor {
    max-height: 350px !important;
    min-height: 280px !important;
  }
}

.editing-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 32px 0 24px 0;

  @media (min-width: map_get($grid-breakpoints, md)) {
    margin-top: auto;
    width: 100%;
  }
}

::v-deep .creation-step {
  padding-bottom: 0;
}

::v-deep .experience {
  margin: 0;
}

::v-deep .field {
  .field__input {
    padding-right: 35px;
    background: #fff;
  }

  .error__btn {
    padding: 10px 7px;
  }
}

::v-deep .experience-form--active {
  @media (min-width: map_get($grid-breakpoints, md)) {
    max-height: 890px;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) {
    max-height: 900px;
  }

  @media (min-width: map_get($grid-breakpoints, xl)) {
    max-height: 720px;
  }
}

::v-deep .experience-form {
  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding: 32px 16px;
  }
}

.creation-form-footer__preview {
  position: absolute;
  top: 0 !important;
  right: 9px;
  background: #fff;
  width: 70px;
  margin: 12px;
  padding: 2px;
  display: block;
  min-width: auto;
  z-index: 22;
  border: 2px solid #4874e6;
  border-radius: 30px;
}

button.creation-form-footer__preview svg {
  width: 17px;
  fill: #4874e6;
}
</style>
