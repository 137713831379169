import Vue from "vue";
import { cloneDeep } from "lodash/lang";

export const experienceFormMixin = {
  computed: {
    isActive() {
      return this.activeId === this.experience.id;
    },
  },

  data() {
    return {
      experienceData: {},
      prefilledData: [],
      isOpenMobileControls: false,
      refreshForm: this.$uuid.v4(),
    };
  },

  methods: {
    setField(fieldData, fieldName) {
      this.experienceData[fieldName] = fieldData;
    },

    cancelEditing() {
      this.$emit("cancel");

      setTimeout(() => {
        this.refreshForm = this.$uuid.v4();
      }, 500);
    },

    editForm() {
      this.closeMobileControls();
      this.$emit("edit", this.experienceData.id);
    },

    scrollIntoFirstError() {
      this.$nextTick(() => {
        const firstInvalidEl = document.querySelector(".field--error");
        if (!firstInvalidEl) return;
        window.scroll({
          top:
            firstInvalidEl.getBoundingClientRect().top + window.scrollY - 100,
          behavior: "smooth",
        });
      });
    },

    isFilled(activeForm) {
      const inputFields = activeForm.querySelectorAll("input");
      const isFilled = Array.from(inputFields).some((input) => {
        return input.value.length > 0;
      });
      return isFilled;
    },

    submitForm() {
      if (this.$v !== undefined) {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.scrollIntoFirstError();
          return;
        }
      }

      const activeForm = document.querySelector(
        ".experience-form.experience-form--active"
      );
      if (activeForm) {
        if (this.isFilled(activeForm)) {
          if (this.experienceData.temporary) {
            this.$emit("create", cloneDeep(this.experienceData));

            return;
          }

          this.$emit("update", this.experienceData);
        } else {
          this.$emit("cancel");
        }
      } else {
        this.$emit("cancel");
      }
    },

    removeExperience() {
      this.$emit("remove", this.experienceData.id);
    },

    toggleMobileControls() {
      this.isOpenMobileControls = !this.isOpenMobileControls;
    },

    closeMobileControls() {
      this.isOpenMobileControls = false;
    },

    checkValidationForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.scrollIntoFirstError();
      }

      return !this.$v.$invalid;
    },
  },

  created() {
    Vue.set(this, "experienceData", this.experience);
  },
};
