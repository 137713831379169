import { cloneDeep } from "lodash/lang";
import { eventBus } from "@/helpers/event-bus";

export const experienceMixin = {
  computed: {
    isEditingExperience() {
      return this.editingExperience !== null;
    },

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  data() {
    return {
      editingExperience: null,
      isMobileDisplay: window.innerWidth < 768,
      isDrag: false,
      resizeObserver: null,
    };
  },

  methods: {
    addExperience(dataObjectKey, defaultObject) {
      const experience = cloneDeep(defaultObject);

      experience.id = this.$uuid.v4();
      experience.index = this[dataObjectKey].length;
      experience.temporary = true;

      this.editingExperience = experience.id;
      this[dataObjectKey].push(experience);

      if (this.isEditing) {
        this.$parent.$emit("editData", this.editingExperience.length);
      }
    },

    async resetIndexes(indexedItems, updatedMethod, cancelEditingMethod) {
      const updatedObjectItems = {
        resume_hash: this.resumeHash(),
        index_orders: [],
      };

      indexedItems.forEach((item, index) => {
        if (Number(item.index) === index) return;

        const newItem = {
          entity_id: item.id,
          index: index,
        };

        updatedObjectItems.index_orders.push(newItem);
      });

      if (!updatedObjectItems.index_orders.length) return;

      await updatedMethod(updatedObjectItems);

      await this.$store.dispatch("resumeCreation/getResume", this.resumeHash());
      cancelEditingMethod();

      if (this.isEditing) {
        this.$parent.$emit("editData", this.editingExperience.length);
      }
    },

    setInitialExperience(initialExp, dataObjectKey) {
      //Sorted works by index inside every object
      const initialWorkHistories = cloneDeep(initialExp);

      const sortedWorks = initialWorkHistories.sort((a, b) => {
        if (a.index > b.index) {
          return 1;
        }
        if (a.index < b.index) {
          return -1;
        }

        return 0;
      });

      this[dataObjectKey] = sortedWorks;

      if (this.isEditing) {
        this.$parent.$emit("editData", sortedWorks.length);
      }
    },

    openEditing(editingExperienceId) {
      this.editingExperience = editingExperienceId;
    },

    dragStart() {
      this.isDrag = true;
    },
  },

  created() {
    eventBus.$on("nextStepResumeCreation", this.goNextStep);
    eventBus.$on("prevStepResumeCreation", this.goPrevStep);
  },

  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.isMobileDisplay = entries[0].contentRect.width < 992;
    });

    this.resizeObserver.observe(document.body);
  },

  beforeDestroy() {
    eventBus.$off("nextStepResumeCreation", this.goNextStep);
    eventBus.$off("prevStepResumeCreation", this.goPrevStep);

    this.resizeObserver.unobserve(document.body);
  },
};
