<template>
  <div
    class="field field--focused"
    :class="{
      'field--required': required,
      'field--error': isInvalid,
    }"
  >
    <label :for="id" class="field__label">
      {{ label }}
      <sup v-if="required">*</sup>
    </label>
    <input
      ref="field"
      v-model="inputValue"
      :id="id"
      :disabled="disabled"
      type="text"
      class="field__input"
      @focus="focus"
      @blur="blur"
      @keyup="onKeyup"
    />
    <div
      id="input_gap"
      @click.prevent="onEditCursorFocus"
      @touchstart="onEditCursorFocus"
    ></div>
    <transition name="fade">
      <div v-if="isOpenDropDown" class="drop-down-wrap">
        <transition name="fade">
          <div v-if="isLoading" class="loading-mask">
            <AppEmbedLoader size="40" color="#4874e6" />
          </div>
        </transition>

        <div class="drop-down">
          <p
            v-for="option of options"
            :key="option.id"
            class="drop-down__item"
            @click.prevent="setOption(option)"
            @touchstart="handleTouchStart($event)"
            @touchend="handleTouchEnd($event, option)"
          >
            <AppIcon componentName="PlusSimpleIcon" />
            <span>{{ option.content }}</span>
          </p>

          <div v-if="!options.length" class="no-results">
            <p>No search results...</p>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="isInvalid"
        class="error"
        @click.prevent="toggleErrorMessage"
        v-click-outside="closeErrorMessage"
      >
        <button type="button" class="error__btn">
          <AppIcon componentName="ErrorIcon" />
        </button>

        <transition name="fade">
          <div v-if="isVisibleErrorMessage" class="error__message">
            <slot name="error" />
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import AppEmbedLoader from "@/components/ui/AppEmbedLoader";

export default {
  name: "AppFieldSelect",
  components: { AppEmbedLoader },

  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "text",
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initFocus: {
      type: Boolean,
      default: false,
    },
    getOptionHandler: {
      type: Function,
    },
    showResultBox: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    onSearchOptionsDebounce() {
      return debounce(function () {
        this.getOptions();
      }, 500);
    },
  },

  data() {
    return {
      id: this.$uuid.v4(),
      onFocus: !!this.value,
      inputValue: "",
      isVisibleErrorMessage: false,
      isOpenDropDown: false,
      options: [],
      isLoading: true,
      startingY: 0,
    };
  },

  methods: {
    focus() {
      if (this.showResultBox == false) {
        this.isOpenDropDown = false;
      } else {
        this.isOpenDropDown = true;
      }
      this.onFocus = true;
      this.$emit("focus");
      this.getOptions();
    },

    blur() {
      this.onFocus = Boolean(this.inputValue);
      this.isLoading = false;
      this.isOpenDropDown = false;
      this.$emit("blur");
    },

    onKeyup(input) {
      this.inputValue = input.target.value;
      this.$emit("input", this.inputValue);
      if (this.showResultBox == false) {
        this.isOpenDropDown = false;
        this.isLoading = false;
      } else {
        this.isOpenDropDown = true;
        this.isLoading = true;
      }
      this.onSearchOptionsDebounce();
    },

    onChange() {
      this.$emit("input", this.inputValue);
    },

    toggleErrorMessage() {
      this.isVisibleErrorMessage = !this.isVisibleErrorMessage;
    },

    closeErrorMessage() {
      this.isVisibleErrorMessage = false;
    },

    async getOptions() {
      this.isLoading = true;
      this.isOpenDropDown = false;

      if (this.showResultBox == true) {
        // this.isOpenDropDown = true;
        try {
          this.isOpenDropDown = true;
          this.options = await this.getOptionHandler(this.inputValue);
          if (this.options.length == 0) {
            setTimeout(() => {
              this.isOpenDropDown = false;
            }, 3000);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        if (this.inputValue != "" && this.inputValue != null) {
          if (this.inputValue.length <= 1) {
            this.isOpenDropDown = false;
            return;
          }

          try {
            this.isOpenDropDown = true;
            this.options = await this.getOptionHandler(this.inputValue);
            if (this.options.length == 0) {
              setTimeout(() => {
                this.isOpenDropDown = false;
              }, 3000);
            }
          } catch (e) {
            console.error(e);
          }
        } else {
          this.isOpenDropDown = false;
        }
      }

      this.isLoading = false;
    },

    setOption(option) {
      this.$refs.field.value = option.content;
      this.inputValue = option.content;
      this.$emit("input", this.inputValue);
      this.onFocus = true;
    },
    onEditCursorFocus() {
      const input = this.$refs.field;
      input.value = "";
      input.value = this.inputValue;
      input.focus();
      input.dispatchEvent(new KeyboardEvent("keydown", { key: "shift" }));
      input.focus();
    },
    handleTouchStart(evt) {
      this.startingY = evt.touches[0].clientY;
    },
    handleTouchEnd(evt, option) {
      var change = evt.changedTouches[0].clientY - this.startingY;
      if (-20 < change && change < 20) {
        this.setOption(option);
      }
    },
  },

  beforeMount() {
    this.inputValue = this.value;
    this.onFocus = !!this.value;
  },

  mounted() {
    if (this.initFocus) {
      this.$refs.field.focus();
    }
  },
};
</script>

<style lang="scss" scoped>
.field {
  width: 100%;
  position: relative;
  margin-bottom: 24px;

  &__label {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    padding: 3px;
    color: $light-gray;
    z-index: 2;
    background-color: #fff;

    sup {
      font-size: 12px;
      line-height: 16px;
      transition: all 0.3s ease;
    }
  }

  &__input {
    display: block;
    width: 100%;
    border: 1px solid #d6d8dc;
    border-radius: 8px;
    height: 56px;
    padding: 10px 55px 10px 16px;
    font-size: 16px;
    position: relative;
    z-index: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:disabled {
      background-color: #fff;
    }
  }

  &--focused {
    .field__label {
      font-size: 13px;
      color: $body-color;
      top: 0;
      cursor: text;
      line-height: 20px;
    }
  }

  &--error {
    .field__input {
      border: 2px solid $red;
      color: $red;
      background: #fff;
    }

    .field__label {
      color: $red;
    }
  }
}

.drop-down-wrap {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 260px;
  padding: 4px;
  border: 1px solid #a3a4a8;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 4px;
  z-index: 10;
  overflow: hidden;
}

.drop-down {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  background-color: #fff;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 8px;
    outline: none;
  }

  &__item {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    background-color: #f4f7fc;
    border-radius: 4px;
    margin: 8px 16px 8px 0;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      flex-shrink: 0;
    }

    &:hover {
      background-color: lighten($primary, 35%);

      svg {
        fill: $primary;
      }
    }
  }
}

::v-deep .error {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    svg {
      width: 20px;
      height: 20px;
      fill: #e26262;
    }
  }

  &__message {
    padding: 12px;
    background-color: #ffe8e8;
    border-radius: 8px;
    position: absolute;
    right: 10px;
    top: 100%;
    width: 210px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: $red;
    z-index: 5;
  }
}

.loading-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.7);
  backdrop-filter: blur(5px);
  z-index: 2;
}

.no-results {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  color: $light-gray;
}
#input_gap {
  z-index: 2;
  right: 3px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 3px;
}
</style>
